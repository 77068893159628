export function normalizeRotation(rotation: number | null | undefined): number {
    if (!rotation) {
        return 0;
    }

    const rounded = roundRotation(rotation);

    // If rotation is between desired values, return it
    if (rounded > 0 && rounded < 360) {
        return rounded;
    }

    const modulo = moduloRotation(rounded);

    // If the rotation has a positive value, return the modulo of 360 degrees
    // The OR clause is added to convert -0 to 0
    if (modulo >= 0) {
        return modulo || 0;
    }

    // If the rotation has a negative value, add 360 to get the positive value
    return modulo + 360;
}

/**
 * Return the module of 360 degrees.
 */
function moduloRotation(rotation: number): number {
    return rotation % 360;
}

/**
 * Round the degrees as we don't use fractions in rotations
 */
function roundRotation(rotation: number): number {
    return Math.round(rotation);
}
