import type { Token } from 'types/store/permissions';

export function hasExpiringTokens(tokens: Token[]): boolean {
    // If there are no tokens, we definitely want to refresh
    if (!tokens?.length) {
        return true;
    }

    // If a token is missing, or has the "refreshing" indicator, we want to refresh the token
    if (tokens.some((token) => !token.key || token.key.includes('token refreshing'))) {
        return true;
    }

    // Now plus 11 minutes
    const compare = Date.now() + 714000;

    // If any of the tokens expire within the next 2 minutes, trigger the refresh
    return tokens.some((token) => token.expiresAt - compare < 0);
}
