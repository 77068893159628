import { onUnmounted, ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';

import { ENDPOINTS } from 'api/endpoints';
import api from 'api/index';

import { hasExpiringTokens } from 'utils/tokens';

import type { DsmTokens, Token } from 'types/store/permissions';

import { useApplicationTokensStore } from 'store/application-tokens';

import { useNotify } from './notify';

const isTokenServiceRunning = ref<boolean>(false);

export function useTokenService() {
    const applicationTokensStore = useApplicationTokensStore();
    const notify = useNotify();

    // The amount of time to wait before triggering
    // 300.000 = 5 minutes
    const INTERVAL = 300000;

    const { resume: startInterval, pause: stopTimeout } = useIntervalFn(
        async () => {
            // If there are no tokens, trigger immediately
            if (!applicationTokensStore.tokens) {
                await fetchTokens();
                return;
            }

            const tokens: Token[] = Object.values(applicationTokensStore.tokens);

            // Check if any of the tokens are going to expire within the next 12 minutes
            if (hasExpiringTokens(tokens)) {
                await fetchTokens();
            }
        },
        INTERVAL,
        { immediate: false, immediateCallback: true }
    );

    async function fetchTokens(): Promise<void> {
        try {
            const response = await api.get<DsmTokens>(ENDPOINTS.TOKENS);
            if (!response?.data) {
                throw new Error('Response data is empty');
            }

            applicationTokensStore.setTokens(response.data);
        } catch (exception) {
            console.error('An error occurred while getting the tokens', (<Error>exception).message);

            notify.admins({
                title: 'Geen applicatie tokens opgehaald',
                text: 'Er is een fout opgetreden bij het ophalen van de applicatie tokens.'
            });
        }
    }

    async function start() {
        if (isTokenServiceRunning.value) {
            console.warn('Token service is already running');
            return;
        }

        isTokenServiceRunning.value = true;
        startInterval();
    }

    function stop() {
        if (!isTokenServiceRunning.value) {
            console.warn('Token service is already stopped');
            return;
        }

        isTokenServiceRunning.value = false;
        stopTimeout();
    }

    onUnmounted(() => {
        stop();
    });

    return { start, stop };
}
