import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import type { DsmTokens } from 'types/store/permissions';

export const useApplicationTokensStore = defineStore('application-tokens', () => {
    const dsmTokens = ref<DsmTokens | null>(null);

    function setTokens(tokens: DsmTokens | null): void {
        dsmTokens.value = tokens;
    }

    return {
        tokens: computed<DsmTokens | null>(() => dsmTokens.value),
        setTokens
    };
});
